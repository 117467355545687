<template>
    <ion-page>
        <ion-content :scroll="false">
            <ion-toolbar
                style="--background: #03a9f4; color: white; --min-height: 36px"
                class="custom-toolbar"
            >
                <div class="row align-center">
                    <slot name="before">
                        <DxButton
                            icon="mdi mdi-chevron-left"
                            type="default"
                            @click="goBack"
                        />
                    </slot>
                    <slot name="center">
                        <div class="grow text-xs-center">HƯỚNG DẪN SỬ DỤNG</div>
                    </slot>
                    <slot name="after">
                        <div class="shrink" :style="'opacity: 0'">
                            <DxButton
                                type="default"
                                icon="mdi mdi-chevron-left"
                                class="ml-1"
                            />
                        </div>
                    </slot>
                </div>
            </ion-toolbar>
            <iframe
                :src="`https://sonphatgroup.vn/huong-dan/${$route.query.name}`"
                title="W3Schools Free Online Web Tutorials"
                style="width: 100%; height: calc(100vh - 48px - 36px)"
            >
            </iframe>
            <!-- ====================================Toast thành công========================================= -->
        </ion-content>
    </ion-page>
</template>
<script>
import { DxList, DxToast } from "devextreme-vue";
import { IonPage, IonContent, IonItem, IonList, IonLabel } from "@ionic/vue";
import { DxButton } from "devextreme-vue";

export default {
    components: {
        DxList,
        DxToast,
        IonPage,
        IonContent,
        DxButton,
        IonItem,
        IonList,
        IonLabel,
    },
    props: {},
    data() {
        return {
            data: [],
            toast: { visible: false, message: "", type: "success" },
        };
    },
    computed: {},
    methods: {
        goBack() {
            let overlay = document.querySelector(".dx-overlay-wrapper");
            if (overlay) {
                overlay.style.display = "block";
            }

            this.$router.go(-1);
        },
    },
    created() {},
    mounted() {
        let overlay = document.querySelector(".dx-overlay-wrapper");
        if (overlay) {
            overlay.style.display = "none";
        }
    },
};
</script>
<style scoped>
>>> .dx-list-item-content {
    padding: unset !important;
}
</style>
<style lang='scss' scoped>
.dx-template-wrapper.dx-item-content.dx-list-item-content {
    border-bottom: 1px solid $border;
}
.thongbao-style {
    color: $text;
}
.hidden {
    background-color: rgb(3 169 244 / 10%) !important;
}
</style>
